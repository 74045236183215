export function initSticky() {
  const stickyMenu = document.getElementById("header");
  const stickyOffset = stickyMenu.offsetTop;

  window.addEventListener("scroll", function(e) {
    requestAnimationFrame(() => {
      if (window.pageYOffset > stickyOffset) {
        stickyMenu.classList.add("is-fixed-top");
      } else {
        stickyMenu.classList.remove("is-fixed-top");
      }
    });
  });
}
