export function subMenu() {
    const width = document.body.offsetWidth;
    const menuDropdownItem = document.querySelectorAll('.has-dropdown > .navbar-link');
    const menuItem = document.querySelectorAll('.navbar-item');

    if (width <= 768) {

        [...menuDropdownItem].forEach(el => {
            let menuDropdown = el.parentElement;
            el.addEventListener('click', (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();
                menuDropdown.classList.toggle("show-dropdown");
            })
        });
    } else {

        [...menuItem].forEach(el => {
            el.addEventListener('mouseenter', (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();
                setTimeout(function () {
                    el.classList.add('hover-dropdown');
                }, 500);
            })
            el.addEventListener('mouseleave', (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();
                setTimeout(function () {
                    el.classList.remove('hover-dropdown');
                }, 500);
            })
        });
    }
}