import Glide from "@glidejs/glide";

export const initGallery = (selector = ".glide") => {
  const galleries = document.querySelectorAll(selector);
  if (galleries.length) {
    const glideOptions = {
      type: "carousel",
      autoplay: 6000,
      perView: 1,
      focusAt: "center",
      breakpoints: {
        800: {
          perView: 1
        },
        480: {
          perView: 1
        }
      }
    };

    galleries.forEach((element, i, l) => {
      let item = element;
      let d = new Date();
      let id = "gallery-" + d.getTime();
      item.setAttribute("id", id);
      let gallery = new Glide("#" + id, glideOptions);

      gallery.mount();
    });
  }
};
