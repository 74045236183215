export function initModalForm (blockSelector=".block-form") {
    const block = document.querySelectorAll(blockSelector);

    block.forEach((element, i) => {
        let trigger  = element.querySelector('.button');
        let target = element.querySelector('.modal');
        let close = element.querySelector('.modal-close');
        

        trigger.addEventListener("click", (event, element) => {
            target.classList.toggle("is-active");
        });

        close.addEventListener("click", (event, element) => {
            target.classList.remove("is-active");
        });

    });     

}