export function initCollapsible(blockSelector = ".collapsible") {
    const block = document.querySelectorAll(blockSelector);

    block.forEach((element) => {
        let icon = 'is-up';
        let blockH = element.parentElement;
        let target = blockH.nextElementSibling;

        if (!(blockH.classList.contains("is-up"))) {
            target.classList.add("is-closed");
        } else {
            icon = 'is-down';
        }

        blockH.classList.add(icon);

        const link = (`
            <span class="icon is-medium">
                <i class="fas fa-chevron-circle-down fa-lg"></i>
            </span>
        `);

        element.insertAdjacentHTML("beforeend", link);

        element.addEventListener("click", (event) => {
            event.preventDefault();
            blockH.classList.toggle("is-up");
            target.classList.toggle("is-closed");
        });
    });
}