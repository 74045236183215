import css from "./scss/style.scss";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
    faTwitter,
    faInstagram,
    faFacebookSquare,
    faFacebook,
    faFacebookF,
    faWhatsappSquare,
    faYoutube,
    faWhatsapp,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./js/external-link";
import { initSticky } from "./js/menu-sticky";
import { initBurger } from "./js/burger";
import { initGallery } from "./js/gallery";
import { initSlide } from "./js/slider";
import { initCopyClipBoard } from "./js/copy-clipboard";
import { initCollapsible } from "./js/collapsible";
import { initVideos, resizeVideos } from "./js/video";
import { initModalForm } from "./js/modal-form";
import { subMenu } from "./js/menu-dropdown";

library.add(
    fas,
    faTwitter,
    faFacebook,
    faFacebookF,
    faFacebookSquare,
    faWhatsappSquare,
    faInstagram,
    faYoutube,
    faWhatsapp,
    faLinkedin
);
dom.watch();

function initDelete() {
    (document.querySelectorAll(".notification .flash-delete") || []).forEach(
        ($delete) => {
            const $notification = $delete.parentNode.parentNode.parentNode;
            if (sessionStorage.getItem("notification") == "closed") {
                $notification.parentNode.removeChild($notification);
            } else {
                $notification.classList.remove("is-hidden");
                $delete.addEventListener("click", () => {
                    $notification.parentNode.removeChild($notification);
                    sessionStorage.setItem("notification", "closed");
                });
            }
        }
    );
}

function initSite() {
    initSlide(".slider");
    initGallery(".gallery");
    initBurger(".navbar-burger");
    initSticky();
    initCopyClipBoard();
    initCollapsible();
    initModalForm();
    subMenu();
    initDelete();
    initVideos();
    resizeVideos();
}
window.addEventListener("resize", resizeVideos);
document.addEventListener("DOMContentLoaded", initSite, false);
